import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g id="B" transform="translate(28.000000, 24.000000)">
          <path
            d="M 25.195 42.012 L 20.508 42.012 L 4.688 21.006 L 20.508 0 L 25.195 0 L 9.053 21.006 L 25.195 42.012 Z M 3.779 42.012 L 0 42.012 L 0 0 L 3.779 0 L 3.779 42.012 Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
